<template>
    <div id='user-list'>
        <v-snackbar v-model="table.snack.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snack.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snack.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <!-- search -->
                <v-text-field v-model='param.query' class='user-search me-3 mb-4' dense hide-details outlined placeholder='搜索订单' clearable></v-text-field>
                <v-col cols='12' sm='8' class=" mb-4">
                    <date-picker placeholder="下单开始时间" v-model="param.start_at" type="datetime" :default-value="formatDateTime(new Date())"></date-picker>
                    <date-picker class="ml-2" placeholder="下单结束时间" v-model="param.end_at" type="datetime" :default-value="formatDateTime(new Date())"></date-picker>
                </v-col>
                <v-spacer></v-spacer>

            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options' :server-items-length='table.total'>
                <template #[`item.order_type`]='{ item }'>
                    {{ table.orderTypes[item.order_type] || '-' }}
                </template>
                <template #[`item.state`]='{ item }'>
                    {{ table.status[item.state] || '-' }}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiCancel, mdiSquareEditOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import orderStoreModule from '../orderStoreModule'
import orderAllocatedList from './useAllocatedOrder'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

export default {
    components: { DatePicker }, setup() {
        const USER_APP_STORE_MODULE_NAME = 'order-taxi-allocated'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, orderStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, fetchAllOrderList, } = orderAllocatedList()

        const pad = (num) => {
            return num.toString().padStart(2, '0');
        }
        const formatDateTime = (date) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hour = date.getHours();
            const minute = date.getMinutes();
            const second = date.getSeconds();
            return `${year}-${pad(month)}-${pad(day)} ${pad(hour)}:${pad(minute)}:${pad(second)}`;
        }
        return {
            headers, formatDateTime,
            param,
            table,
            fetchAllOrderList,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiCancel, mdiExportVariant, mdiAccountOutline, mdiCheckboxMarkedCircleOutline },
        }
    },
} </script>
<style lang='scss'>
@import '@core/preset/preset/apps/user.scss';
</style>
